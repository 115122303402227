import '~/scripts/integrations/jquery-extended';
import Pagelet from '~/scripts/lib/Pagelet';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var Page = function() {
    var badgeImageEditUploader, that = this;

    this.addEvents();

    //Place the tooltip in the top for delete link
    if ($.fn.tooltip) {
      $(".button-no-chrome").tooltip({
        placement: "top",
        delay: 500
      });
    }

    // Hide badge image edit container at load
    $("#custom-badges .badge_image_edit_container .inner_container").hide();

    $document.on("click", ".change_badge_image", function(){
      var $relevantBtn = $($(this)[0]);
      var $relevantBadgeContainer = $relevantBtn.closest('.badge_image_edit_container');

      // Bind jquery file uploader.
      badgeImageEditUploader = new window.R.Uploader(
        $relevantBadgeContainer,
        function(e,json){
          that.setupBadgeAfterImageChange(json);
        },
        {
          submitBtn: $relevantBadgeContainer.find(".change_badge_image"),
          fileUpload: {
            properties: {
              autoUpload: true,
              url: $relevantBadgeContainer.attr("data-endpoint"),
              formData: $relevantBadgeContainer.find("#badge_id").serializeArray(),
              type: 'patch'
            },
            // The presence of 'add' callback interferes with autoUpload functionality, therefore exclude it.
            excludedCallbacks: ["add"]
          }
        }
      );

      changeStateOfBadgeImageEditContainer($relevantBadgeContainer);
    });
  };

  function changeStateOfBadgeImageEditContainer(container) {
    var $relevantBadgeContainer = container;
    if ($relevantBadgeContainer.find('.inner_container').is(":visible") === false) {
      $relevantBadgeContainer.find('.inner_container').show();
    } else {
      $relevantBadgeContainer.find('.inner_container').hide();
      $relevantBadgeContainer.find('.error').remove();
    }
  }

  Page.prototype.removeEvents = function() {
    for(var i=0;i++;i<this.res.length) {
      delete this.res[i];
    }

    $("#custom-badges-disabled-link").unbind();
    $('a[data-toggle="tab"]').off('shown');

    if (this.$pointValues) {
      this.$pointValues.unbind("ajax:complete");
      this.$pointValues.unbind("ajax:complete");
    }

    $window.unbind("ajaxify:success:kioskUrlUpdated");
    $document.off("ajax:success", '.edit_reward');
    $(".badge-type-selectors input[type='radio']").off();
    $document.off('click', '.role_toggle');
    $document.off('click', '#all_teams_box');
    $document.off("click", ".change_badge_image");
  };

  Page.prototype.addEvents = function() {
    this.pagelet = new Pagelet();

    $document.on("keypress", ".requires-approval-wrapper .select2-search__field", function (e) {
      if (isNaN(e.key)) {
        return false;
      }
    });

    var $imgs;

    this.setupBadges($document);
    $imgs = $("#custom_badges .custom-badge-image");
    if ($imgs.first().attr("src") === "") {
      $imgs.each(function () {
        var $this = $(this);
        $this.attr("src", $this.data("img"));
        $this.attr("style", "");
      });
    }

    this.syncMinMaxPointsBox();
  };

  // To be called after badge edit image uploader returns with success.
  Page.prototype.setupBadgeAfterImageChange = function(json) {
    var updatedBadgeId = json.badge_id;
    var $updatedBadge =  $("#custom-badges #badge-" + updatedBadgeId);
    var $toBeUpdatedImgTag = $updatedBadge.find("img");
    $toBeUpdatedImgTag.attr('src', json.badge_image_url + '?' + new Date().getTime()).fadeIn(2000);
    $updatedBadge.find('.inner_container').hide();
  };

  Page.prototype.setupBadges = function($parent) {
    var that = this;

    // $parent is to scope the events
    // on page load, parent is $document
    // however, when a page is created, $parent will be wrapper of new badge
    // so we can re-add the events safely without doubling up on existing badges
    var $achievementInputs = $parent.find(".achievement-wrapper .form-check-input");

    $achievementInputs.on("change", function() {
      var $this = $(this);
      var $parent = $this.closest(".badge-information-column");
      var $achievementOptions = $parent.find(".achievement-options");
  
      $parent.find(".badge-options:not(.static-option)").addClass("d-none");
  
      $achievementOptions.toggleClass("d-none", !$this.prop("checked"));
    });

    var $requiresApprovalCheckboxes = $(".requires-approval-wrapper .requires-approval:input[type=checkbox]");

    $requiresApprovalCheckboxes.on('click', function(){
      var $this = $(this);
      var $parent = $this.closest(".requires-approval-wrapper");
      if ($this.prop('checked')) {
        $parent.find(".requires-approval-options").slideDown();
      } else {
        $parent.closest(".recognize-badge").find(".points-label").slideDown();
        $parent.find(".requires-approval-options").slideUp();
      }
    });

    this.bindPointValuesSelect2ForBadgesThatRequireApproval();
    this.bindApproverSelect2ForBadgesThatRequireApproval();

    new Select2(function() {
      $parent.find('.company-role-select').select2(R.utils.companyRoleSelect2TemplateOpts({
        tokenSeparators: [',', ' ']
      }));
    });

    this.bindapprovalStrategyWrapper();
    this.bindBadgePointCalculators();
  };

  Page.prototype.bindapprovalStrategyWrapper = function() {
    $document.off('change', '.approver-select');
    $document.on('change', '.approver-select', function(){
      var selectedValueText = $(this).find("option:selected").text();
      var $approvalStrategyWrapper = $(this).parent().parent().find(".approval-strategy-wrapper");
      var $approvalStrategyCheckbox = $approvalStrategyWrapper.find(".approval-strategy:input[type=checkbox]");

      var showApprovalStrategyWrapper = selectedValueText === "Manager";
      var resetApprovalStrategyCheckbox = !showApprovalStrategyWrapper ;

      showApprovalStrategyWrapper ?
        $approvalStrategyWrapper.removeClass('d-none') : $approvalStrategyWrapper.addClass('d-none')

      if(resetApprovalStrategyCheckbox) {
        $approvalStrategyCheckbox.prop('checked', false);
      }
    });
  };

  Page.prototype.bindPointValuesSelect2ForBadgesThatRequireApproval = function() {
    var $pointValuesSelect = $('.point-values-select');
    var pointValuesSelectPlaceholder = $pointValuesSelect.attr('placeholder');
    new Select2(function () {
      $pointValuesSelect.select2({
        tags: true,
        tokenSeparators: [',', ' '],
        placeholder: window.R.utils.isIE() !== false && window.R.utils.isIE() < 12 ? '' : pointValuesSelectPlaceholder
      });
    });
  };

  Page.prototype.bindApproverSelect2ForBadgesThatRequireApproval = function() {
    var $approverSelect = $('.approver-select');
    var approverSelectPlaceholder = $approverSelect.attr('placeholder');
    new Select2(function () {
      $approverSelect.select2({
        placeholder: window.R.utils.isIE() !== false && window.R.utils.isIE() < 12 ? '' : approverSelectPlaceholder
      });
    });
  };

  Page.prototype.bindBadgePointCalculators = function() {
    var that = this;

    var selector = '.badge-points, .sending-limit-wrapper input, .sending-limit-wrapper select, .requires-approval, .point-values-select';
    $document.on('keyup mouseup change', selector, function(){
      // console.log("calculator events triggered");
      that.updateBadgeBudget($(this).parents(".recognize-badge"));
    });
    $(selector).change();

    $(".company-role-select").on('change', function(evt){
      var $select = $(evt.target);
      var $badge = $select.parents(".recognize-badge");
      var roleIds = $select.val();
      var userCountHtml, $userCountWrapper;

      $.ajax({
        url: gon.counts_users_url,
        data: {role_ids: roleIds},
        dataType: 'json',
        success: function(data){
          var uniqueUserCount = roleIds.length > 0 ? data.unique_users_in_role : data.total;
          $badge.data('sendableusercount', uniqueUserCount);
          $userCountWrapper = $badge.find(".user-role-count-wrapper");

          if(roleIds.length > 0) {
            $userCountWrapper.text(function(i,txt){ return txt.replace(/\d+/, uniqueUserCount)});
            userCountHtml = $userCountWrapper.html();

            if(uniqueUserCount === 1) {
              userCountHtml = userCountHtml.replace('people', 'person');
            } else {
              userCountHtml = userCountHtml.replace('person', 'people');
            }
            $userCountWrapper.html(userCountHtml);
            $userCountWrapper.show();
          } else {
            $userCountWrapper.hide();
          }

          that.updateBadgeBudget($badge);
        }
      });
    });
  };

  Page.prototype.updateBadgeBudget = function($badge) {
    var sendFrequency = parseInt($badge.find("input.sending-frequency").val());
    var badgePoints = getBadgePoints($badge);
    var $snippet = $badge.find(".point-money-snippet");
    var totalPointValue, totalMoneyValue;
    var badgeValue;


    if(gon.show_budgets && !badgeIsNomination($badge)) {
      var sendIntervalId = parseInt($badge.find("select.sending-interval-select").val());
      var conversionFactor = R.utils.interval_conversion_factor(gon.reset_interval_id, sendIntervalId);
      var userCount = $badge.data('sendableusercount');
      var perEmployeeValue;

      $badge.find(".employee-count").text(userCount);


      if (!sendFrequency) {
        sendFrequency = 0;
      }

      // sendInterval is a more frequent interval than reset interval
      // eg, weekly vs quarterly
      if(conversionFactor < 0) {
        totalPointValue = (sendFrequency * badgePoints * userCount) / Math.abs(conversionFactor);
      } else {
        totalPointValue = sendFrequency * badgePoints * userCount * conversionFactor;
      }

      perEmployeeValue = userCount ? (totalPointValue / userCount) : 0;

      // console.log("totalPointValue", totalPointValue);

      if (!isNaN(totalPointValue)) {
        totalMoneyValue = window.R.utils.formatCurrency(totalPointValue / gon.points_to_currency_ratio);
      }

      badgeValue = badgePoints === 0 ? window.R.utils.formatCurrency(0) : window.R.utils.formatCurrency(badgePoints / gon.points_to_currency_ratio);

      $badge.find(".point-to-currency").text(badgeValue);

      $snippet.data('pointValue', totalPointValue);
      $snippet.find(".point-snippet-value").html(totalPointValue.toLocaleString());
      $snippet.find(".money-snippet-value").html(totalMoneyValue);

      $badge.find(".point-snippet-per-employee-value").html(perEmployeeValue.toLocaleString());
      $badge.find(".money-snippet-per-employee-value").html(window.R.utils.formatCurrency(perEmployeeValue / gon.points_to_currency_ratio));

      $snippet.show();
    } else {
      $snippet.hide();
    }
    this.updateTotalBudget();
  };

  function getBadgePoints($badge) {
    var badgePoints;

    var approval = $badge.find(".requires-approval").is(":checked");

    if (approval) {
      badgePoints = Math.max.apply(null, $badge.find(".point-values-select").val())
    } else {
      badgePoints = parseInt($badge.find(".badge-points").val());
    }

    if (!badgePoints || badgePoints <= -Infinity || badgePoints >= Infinity) {
      badgePoints = 0;
    }

    return badgePoints;
  }

  // point-to-currency

  Page.prototype.updateTotalBudget = function() {
    var $badgeForm = $document.find("#custom-badges");
    var $budgetWrapper = $document.find("#budget-wrapper");
    var pointTotal = 0;

    $badgeForm.find(".badge.enabled .point-money-snippet").each(function(){
      var $el = $(this);
      var val = parseFloat($el.data('pointValue'));
      if(val) {
        pointTotal += val;
      }
    });

    if(gon.show_budgets) {
      var totalMoneyValue = window.R.utils.formatCurrency(pointTotal / gon.points_to_currency_ratio);
      $("#point-budget-value").html(pointTotal.toLocaleString());
      $("#money-budget-value").html(totalMoneyValue);
      $budgetWrapper.show();
    } else {
      $budgetWrapper.hide();
    }

  };

  Page.prototype.syncMinMaxPointsBox = function() {
    let minPointsBox = $('#company_badge_min_points');
    let maxPointsBox = $('#company_badge_max_points');

    function minPoints() {
      return parseInt(minPointsBox.val());
    }

    function maxPoints() {
      return parseInt(maxPointsBox.val());
    }

    minPointsBox.on('change', function(){
      if(minPoints() > maxPoints()) {
        maxPointsBox.val(minPoints());
      }
    });

    maxPointsBox.on('change', function(){
      if(minPoints() > maxPoints()){
        minPointsBox.val(maxPoints());
      }
    });
  };

  function badgeIsNomination($badge) {
    var nomCheckbox = $badge.find(".nomination-wrapper input[type='checkbox']");
    return nomCheckbox.is(':checked');
  }

  return Page;

})();
